/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CenterCol from '../../center-col'
import ImageFill, { ImageInline } from '../../image'
import Section from '../../section'
import VideoWrapper from '../../video-wrapper'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const VideoRow = (props) => {
  const { videoType, embed, mux, label, ...otherProps } = props

  return (
    <CenterCol span={8}>
      <VideoWrapper
        videoType={videoType}
        embed={embed}
        mux={mux}
        label={label}
      />
    </CenterCol>
  )
}

export default VideoRow
