/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction, compact } from 'lodash'
import { graphql } from 'gatsby'

import Awards from '../awards'
import { PastProductionsCardSection } from '../card-section'
import Credits from '../credits'
import Introduction from '../introduction'
import { FullHeader } from '../page-header'
import PageWrapper from '../page-wrapper'
import ProductionBlocks from '../production-blocks'
import Section from '../section'
import Title from '../title'
import Link from '../link'
import Button from '../button'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  FlexColEmpty,
  HStack,
  VStack,
} from '../../styles/els'

import {
  formatImage,
  renderHTML,
  formatDate,
  LinkTypes,
} from '../../lib/helpers'
import { FullsizeImage, InlineImage } from '../../lib/fragments'

import { setValue, useThemeUISetValue } from '../../styles/utils'
import Card from '../card'
import CenterCol from '../center-col'

const ProductionContent = (props) => {
  const {
    title,
    color,
    intro,
    about,
    sub,
    credits,
    showCreditNote,
    creditNote,
    awards,
    downloads,
    content,
  } = props

  const { theme } = useThemeUI()
  const p = useThemeUISetValue('headingPadding', theme)

  return (
    <React.Fragment>
      <Section sx={{ py: p }}>
        <Title title={title} sub={sub} color={color} />
        <FlexCols>
          <FlexCol across={[12, null, null, 7]} base={12}>
            {intro ? (
              <div sx={{ variant: 'text.regular_36', pb: about ? 6 : null }}>
                {renderHTML(intro)}
              </div>
            ) : null}
            {about ? (
              <div sx={{ variant: 'textBlock.body' }}>{renderHTML(about)}</div>
            ) : null}
            {awards && awards.length > 0 ? (
              <div sx={{ pt: 7 }}>
                <Card>
                  <Awards awards={awards} color={color} secondary={true} />
                </Card>
              </div>
            ) : null}
          </FlexCol>
          <FlexColEmpty across={1} base={12} />
          <FlexCol across={[3, null, null, 1]} base={3}>
            <VStack>
              {credits || (showCreditNote && creditNote) ? (
                <div>
                  <Credits
                    credits={credits}
                    note={showCreditNote && creditNote ? creditNote : null}
                  />
                </div>
              ) : null}
              {downloads && downloads.length > 0 ? (
                <div>
                  <h5
                    sx={{
                      variant: 'text.mono',
                      color: 'grey1',
                      pb: 3,
                    }}
                  >
                    Downloads
                  </h5>
                  <HStack space={3}>
                    {downloads.map((_d, _i) => {
                      return (
                        <Button
                          to={get(_d, 'file.mediaItemUrl')}
                          type={LinkTypes.EXTERNAL}
                          color={color}
                          label={_d.label}
                        />
                      )
                    })}
                  </HStack>
                </div>
              ) : null}
            </VStack>
          </FlexCol>
        </FlexCols>
      </Section>
      {content && content.length > 0 ? (
        <ProductionBlocks blocks={content} pageColor={color} />
      ) : null}
    </React.Fragment>
  )
}

export default ProductionContent
