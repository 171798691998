/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Section from '../section'

import { renderHTML } from '../../lib/helpers'

import { Cont, FlexCols, FlexCol, VStack } from '../../styles/els'

const CreditRow = (props) => {
  const { role, who, note, ...otherProps } = props

  return (
    <div {...otherProps}>
      {role ? (
        <div
          sx={{
            variant: 'text.mono',
            color: 'grey1',
          }}
        >
          {role}
        </div>
      ) : null}
      {note || who ? (
        <div
          sx={{
            variant: note ? 'textBlock.body' : 'text.regular_20',
            color: note ? 'grey1' : null,
          }}
        >
          {note ? renderHTML(note) : who}
        </div>
      ) : null}
    </div>
  )
}

const Credits = (props) => {
  const { credits, note, ...otherProps } = props

  return (
    <VStack space={0} {...otherProps}>
      {credits && credits.length > 0
        ? credits.map((_credit, _i) => {
            return (
              <CreditRow key={_i} {..._credit} sx={{ pt: _i > 0 ? 3 : null }} />
            )
          })
        : null}
      {note ? (
        <CreditRow
          note={note}
          sx={{ pt: credits && credits.length > 0 ? 4 : null }}
        />
      ) : null}
    </VStack>
  )
}

export default Credits
