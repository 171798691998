/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction, compact } from 'lodash'
import { graphql } from 'gatsby'

import Awards from '../components/awards'
import { PastProductionsCardSection } from '../components/card-section'
import Credits from '../components/credits'
import Introduction from '../components/introduction'
import { FullHeader } from '../components/page-header'
import PageWrapper from '../components/page-wrapper'
import ProductionBlocks from '../components/production-blocks'
import Section from '../components/section'
import Title from '../components/title'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  FlexColEmpty,
} from '../styles/els'

import { formatImage, renderHTML, formatDate } from '../lib/helpers'
import { FullsizeImage, InlineImage } from '../lib/fragments'

import { setValue, useThemeUISetValue } from '../styles/utils'
import Card from '../components/card'
import CenterCol from '../components/center-col'
import ProductionContent from '../components/production-content'

const Production = (props) => {
  const { data } = props

  const title = get(data, 'production.title')
  const id = get(data, 'production.id')
  const pageColor = get(data, 'production.acfProduction.pageColour')
  const image = formatImage(get(data, 'production.acfProduction.image'))
  const intro = get(data, 'production.acfProduction.intro')
  const about = get(data, 'production.acfProduction.about')
  const place = get(data, 'production.acfProduction.place')
  const dateStart = get(data, 'production.acfProduction.dateStart')
  const dateEnd = get(data, 'production.acfProduction.dateEnd')
  const credits = get(data, 'production.acfProduction.credits')
  const showCreditNote = get(data, 'production.acfProduction.showCreditNote')
  const creditNote = get(data, 'production.acfProduction.creditNote')
  const awards = get(data, 'production.acfProduction.awards')
  const downloads = get(data, 'production.acfProduction.downloads')
  const content = get(data, 'production.acfProduction.content')

  const otherProductions = get(
    data,
    'production.acfProduction.otherProductions'
  )

  const dateString = formatDate(dateStart, dateEnd)
  const sub = compact([dateString, place])

  return (
    <PageWrapper>
      <FullHeader image={image} />
      <ProductionContent
        title={title}
        sub={sub}
        intro={intro}
        about={about}
        credits={credits}
        showCreditNote={showCreditNote}
        creditNote={creditNote}
        awards={awards}
        downloads={downloads}
        content={content}
        color={pageColor}
      />
      {otherProductions && otherProductions.length > 0
        ? otherProductions.map((_oP, _i) => {
            return <ProductionContent {..._oP} color={pageColor} />
          })
        : null}
      <PastProductionsCardSection toFilter={id} inset={true} />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($id: String) {
    production: wpProduction(id: { eq: $id }) {
      title
      uri
      id
      acfProduction {
        pageColour
        image {
          ...FullsizeImage
        }
        place
        dateStart
        dateEnd
        intro
        about
        credits {
          role
          who
        }
        showCreditNote
        creditNote
        awards {
          year
          award
          detail
          label
        }
        downloads {
          label
          file {
            mediaItemUrl
          }
        }
        content {
          __typename
          ... on WpProduction_Acfproduction_Content_ImageQuote {
            blocks {
              __typename
              ... on WpProduction_Acfproduction_Content_ImageQuote_Blocks_Image {
                imageRatio
                image {
                  ...InlineImage
                }
              }
              ... on WpProduction_Acfproduction_Content_ImageQuote_Blocks_Quote {
                by
                quote
                stars
              }
            }
          }
          ... on WpProduction_Acfproduction_Content_Video {
            videoType
            embed
            mux
            label
          }
        }
        otherProductions {
          title
          intro
          about
          credits {
            role
            who
          }
          showCreditNote
          creditNote
          awards {
            year
            award
            detail
            label
          }
          downloads {
            label
            file {
              mediaItemUrl
            }
          }
          content {
            __typename
            ... on WpProduction_Acfproduction_otherProductions_Content_ImageQuote {
              blocks {
                __typename
                ... on WpProduction_Acfproduction_otherProductions_Content_ImageQuote_Blocks_Image {
                  imageRatio
                  image {
                    ...InlineImage
                  }
                }
                ... on WpProduction_Acfproduction_otherProductions_Content_ImageQuote_Blocks_Quote {
                  by
                  quote
                  stars
                }
              }
            }
            ... on WpProduction_Acfproduction_otherProductions_Content_Video {
              videoType
              embed
              mux
              label
            }
          }
        }
      }
    }
  }
`

export default Production
