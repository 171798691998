/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import {
  compact,
  isFinite,
  countBy,
  keys,
  each,
  isArray,
  isArguments,
  find,
  findIndex,
} from 'lodash'

import CenterCol from '../../center-col'
import ImageFill, { ImageInline, RatioBox } from '../../image'
import { QuoteText } from '../../quote'
import Section from '../../section'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import {
  Cont,
  FlexCols,
  FlexCol,
  FlexColEmpty,
  VStack,
} from '../../../styles/els'

const BlockTypes = {
  IMAGE: 'image',
  QUOTE: 'quote',
}

const mapBlockType = (blockType) => {
  switch (blockType) {
    case 'WpProduction_Acfproduction_Content_ImageQuote_Blocks_Image':
    case 'WpProduction_Acfproduction_otherProductions_Content_ImageQuote_Blocks_Image':
      return BlockTypes.IMAGE
    case 'WpProduction_Acfproduction_Content_ImageQuote_Blocks_Quote':
    case 'WpProduction_Acfproduction_otherProductions_Content_ImageQuote_Blocks_Quote':
      return BlockTypes.QUOTE
    default:
      return null
  }
}

const countBlocks = (blocks) => {
  return countBy(blocks, (_o) => _o.type)
}

const shouldStretch = (blocks, { canStretch }) => {
  if (!canStretch) {
    return false
  } else if (blocks.length === 1 && blockIsPortrait(blocks[0])) {
    return true
  } else if (blocks.length === 2) {
    const count = countBlocks(blocks)
    return count[BlockTypes.QUOTE] === count[BlockTypes.IMAGE]
  } else {
    return false
  }
}

const packCol = (_blocks, props = {}) => {
  const { canStretch } = props

  const blocks = isArray(_blocks) ? _blocks : [_blocks]
  return {
    items: blocks,
    stretch: shouldStretch(blocks, props),
  }
}

const packAllCols = (blocks) => {
  return blocks.map((_block) => {
    return packCol(_block)
  })
}

const allBlocksSameType = (blocks, count) => {
  let bool = false
  for (const [k, v] of Object.entries(BlockTypes)) {
    if (count[v] === blocks.length) {
      bool = true
    }
    if (bool) {
      break
    }
  }

  return bool
}

const blockIsPortrait = (block) => {
  return block.type === BlockTypes.IMAGE && block.imageRatio >= 1
}

const blocksToCols = (_blocks) => {
  const blocks = compact(
    _blocks.map((_block) => {
      const _blockType = mapBlockType(_block.__typename)
      switch (_blockType) {
        case BlockTypes.IMAGE:
          const image = formatImage(_block.image)
          const setRatio = ratioForImage(_block.imageRatio)
          return image
            ? {
                ..._block,
                type: _blockType,
                image,
                imageRatio: isFinite(setRatio)
                  ? setRatio
                  : image.height / image.width,
              }
            : null
        default:
          return {
            ..._block,
            type: _blockType,
          }
      }
    })
  )

  const cols = []
  const count = countBlocks(blocks)

  if (blocks.length <= 1 || allBlocksSameType(blocks, count)) {
    return packAllCols(blocks)
  } else if (blocks.length == 2) {
    cols.push(...packAllCols(blocks))
    const imageBlockIndex = findIndex(blocks, { type: BlockTypes.IMAGE })
    if (imageBlockIndex >= 0 && !blockIsPortrait(blocks[imageBlockIndex])) {
      cols[imageBlockIndex].width = 8
      cols[imageBlockIndex === 1 ? 0 : 1].width = 4
    }
    return cols
  } else {
    let items = []
    const packColProps = {
      canStretch: true,
    }
    for (let i = 0; i < blocks.length; i++) {
      const _block = blocks[i]
      if (
        // Max 2
        items.length >= 2 ||
        // No 2 images in a row
        (items.length > 0 &&
          _block.type === BlockTypes.IMAGE &&
          items[items.length - 1].type === BlockTypes.IMAGE) ||
        // If previous portrait
        (items.length > 0 && blockIsPortrait(items[items.length - 1])) ||
        // If this portrait
        (items.length > 0 && blockIsPortrait(_block)) ||
        (items.length > 0 &&
          _block.type === BlockTypes.QUOTE &&
          i < blocks.length - 1 &&
          blocks[i + 1].type === BlockTypes.QUOTE)
      ) {
        cols.push(packCol(items, packColProps))
        items = []
      }
      items.push(_block)
    }
    cols.push(packCol(items, packColProps))
  }

  return cols
}

const ColItem = (props) => {
  const { item, stretch, pageColor } = props

  switch (item.type) {
    case BlockTypes.IMAGE:
      return stretch ? (
        <React.Fragment>
          <RatioBox ratio={item.imageRatio} />
          <ImageFill image={item.image} />
        </React.Fragment>
      ) : (
        <ImageInline image={item.image} ratio={item.imageRatio} />
      )
    case BlockTypes.QUOTE:
      return (
        <QuoteText
          quote={item.quote}
          stars={item.stars}
          by={item.by}
          color={pageColor}
          sx={{
            py: [4, null, null, 0],
          }}
        />
      )
    default:
      return null
  }
}

const Col = (props) => {
  const { items, stretch, ...otherProps } = props

  return items && items.length > 0 ? (
    items.length > 1 ? (
      <VStack
        sx={{
          height: stretch ? '100%' : null,
        }}
      >
        {items.map((_item, _i) => {
          return (
            <div
              key={_i}
              sx={{
                flex: _item.type === BlockTypes.QUOTE ? 1 : null,
              }}
            >
              <ColItem item={_item} {...otherProps} />
            </div>
          )
        })}
      </VStack>
    ) : (
      <div sx={{ position: 'relative', flex: stretch ? 1 : null }}>
        <ColItem item={items[0]} stretch={stretch} {...otherProps} />
      </div>
    )
  ) : null
}

const ImageQuoteRow = (props) => {
  const { blocks, pageColor, rowIndex, ...otherProps } = props
  const cols = useMemo(() => {
    return blocksToCols(blocks)
  }, [blocks])

  const flexEnd = (rowIndex + 1) % 4 > 2

  return cols && cols.length > 0 ? (
    cols.length === 1 ? (
      <CenterCol span={blockIsPortrait(cols[0].items[0]) ? 6 : 10}>
        <Col items={cols[0].items} pageColor={pageColor} />
      </CenterCol>
    ) : (
      <FlexCols
        sx={{
          alignItems: flexEnd ? 'flex-end' : 'flex-start',
        }}
      >
        {cols.map((_col, _i) => {
          const _colHasWidth = isFinite(_col.width)
          return (
            <FlexCol
              key={_i}
              across={[1, null, null, _colHasWidth ? _col.width : 1]}
              base={[1, null, null, _colHasWidth ? 12 : cols.length]}
              sx={{
                alignSelf: _col.stretch ? 'stretch' : null,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Col {..._col} pageColor={pageColor} />
            </FlexCol>
          )
        })}
      </FlexCols>
    )
  ) : null
}

export default ImageQuoteRow
