/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Section from '../section'

import { renderHTML } from '../../lib/helpers'

import { Cont, FlexCols, FlexCol, VStack } from '../../styles/els'

import ImageQuote from './blocks/image-quote'
import Video from './blocks/video'

const componentForType = (type) => {
  switch (type) {
    case 'WpProduction_Acfproduction_Content_ImageQuote':
    case 'WpProduction_Acfproduction_otherProductions_Content_ImageQuote':
      return ImageQuote
    case 'WpProduction_Acfproduction_Content_Video':
    case 'WpProduction_Acfproduction_otherProductions_Content_Video':
      return Video
    default:
      return null
  }
}

const ProductionBlocks = (props) => {
  const { blocks, pageColor, ...otherProps } = props

  return blocks && blocks.length > 0 ? (
    <Section sx={{ pb: 9 }}>
      <VStack>
        {blocks.map((_block, _i) => {
          const _type = _block.__typename
          const Component = componentForType(_type)
          return Component ? (
            <div key={_i}>
              <Component {..._block} pageColor={pageColor} rowIndex={_i} />
            </div>
          ) : null
        })}
      </VStack>
    </Section>
  ) : null
}

export default ProductionBlocks
